import logo from './images/logo-dead-below-deck.png';
import hak_logo from './images/hak-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="top-texture"></div>
        <div class="header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div class="bottom-texture"></div>
        <section>
        <p>Click here to view digital clues and talk to Jen</p>
          <a
            className="App-link"
            href="https://essex-luxury-sea-tours.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Play the Game
          </a>
        </section>
        <section>
        <p>Click here for the inventory list, <br></br>transcripts, and to check your work (all spoilers are hidden)</p>
        <a
          className="App-link"
          href="https://members.huntakiller.com/dead-below-deck"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hints & Reveals
        </a>
        </section>
        <section>
        <p>Don’t own Dead Below Deck? Buy it here!</p>
        <a
          className="App-link"
          href="https://www.huntakiller.com/products/dead-below-deck?_pos=1&_psq=dead&_ss=e&_v=1.0"
          target="_blank"
           rel="noopener noreferrer"
        >
          Buy It Now
        </a>
        </section>
        
      </header>
      <footer>
        <a
          href="https://www.huntakiller.com"
          target="_blank"
           rel="noopener noreferrer"
        >
         <img src={hak_logo} alt="hunt a killer logo" />
        </a>
      </footer>
    </div>
  );
}

export default App;
